<template>
  <div class="pay-devices">
    <div class="device-device-data-list">
      <el-table :data="devices"
                ref="multipleTable"
                max-height="420px"
                @select="listChange"
                @select-all="handleBatch">
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column label="设备编号" align="center" prop="deviceUuid"></el-table-column>
        <el-table-column label="设备名称" align="center" prop="deviceName"></el-table-column>
        <el-table-column label="注册时间" align="center" min-width="130">
          <template slot-scope="scope">
            <span>{{new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
  </div>

</template>

<script>
  import {drinkSyncDeviceUnion, drinkSyncDeviceList} from '@/api/equipment/drink'

  export default {
    name: '',
    props: {},
    components: {},
    computed: {},
    mounted: function () {
    },
    data: function () {
      return {
        devices: [],
        loading: false,
        payUuid: '',
        pagination: {
          name: '',
          productUuid: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
        allocatedEnterprise: {},
        checkInfo: {
          deviceUuid: []
        }
      }
    },

    methods: {
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        drinkSyncDeviceList(this.pagination).then(res => {
          this.devices = res.data || []
          this.$nextTick(function () {
            this.judoCheck(this.devices)
          })
        })
      },

      judoCheck(devices) {
        for (let j = 0; j < devices.length; j++) {
          this.$set(devices[j], 'checked', false)
        }

        for (let j = 0; j < devices.length; j++) {
          for (let i = 0; i < this.checkInfo.deviceUuid.length; i++) {
            if (devices[j].deviceUuid === this.checkInfo.deviceUuid[i].deviceUuid) {
              this.$refs.multipleTable.toggleRowSelection(devices[j])
              break
            }
          }
        }
        return devices
      },

      handleBatch(value) {
        for (let j = 0; j < this.devices.length; j++) {
          for (let i = 0; i < this.checkInfo.deviceUuid.length; i++) {
            if (this.devices[j].deviceUuid === this.checkInfo.deviceUuid[i].deviceUuid) {
              this.checkInfo.deviceUuid.splice(i, 1)
            }
          }
        }

        if (value.length !== 0) {
          for (let j = 0; j < value.length; j++) {
            this.checkInfo.deviceUuid.push(value[j])
          }
        }
      },


      listChange(selection) {
        this.handleBatch(selection)
      },


      loadDevices(productUuid, name, checkInfo = []) {
        let vue = this
        this.pagination.name = name
        this.pagination.productUuid = productUuid
        this.checkInfo.deviceUuid = checkInfo

        drinkSyncDeviceUnion(this.pagination).then(res => {
          vue.devices = res.data.list || []
          vue.pagination.total = parseInt(res.data.total)
          this.$nextTick(function () {
            this.judoCheck(vue.devices)
          })
        })
      },
      getDevices() {
        return this.checkInfo.deviceUuid
      }
    },
  }
</script>

<style lang="scss">
  .pay-devices {
    padding: $padding;
    margin-top: $margin;
    display: flex;
    flex-direction: column;

    .device-device-title {
      .device-device-input-filter {
        input {
          border: none;
          border-bottom: 1px solid;
          border-radius: 0;
        }
      }

      .device-device-status-filter {
        max-width: 124px;

        .el-input--suffix .el-input__inner {
          padding-right: 0;
        }
      }

      .more {
        font-size: .6rem;
        height: 28px !important;
        line-height: 28px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        span {
          cursor: pointer;
        }
      }
    }

    .device-device-data-list {
      margin-top: $padding;
      flex: 1;

      .device-device-status {
        .device-device-info-img {
        }

        .device-device-info-detail {

          p {
            margin-bottom: $padding;
          }

          p:first-child {
            font-size: .8rem;
          }
        }
      }

      .device-device-address {
        p {
          margin-bottom: $padding;
        }
      }

      .el-table {
        thead {
          tr {
            th {
              border-bottom: none;
            }
          }
        }

      }

      .el-table--group::after, .el-table--border::after, .el-table::before {
        background-color: transparent;
      }

      .el-table__row:hover {
      }

    }

    .pagination {
      margin: $padding;
    }
  }

</style>

<template>

  <div class="drink-store-edit">
    <div class="drink-store-edit-operate">
      <p class="drink-store-edit-operate-title clear-fix">
        <span>同步饮品配方</span>


        <el-button class="error delete-drink-store float-right" type="text" size="mini" @click="handleDeleteDrinkSync">
          <i
            class="iconfont iconshanchu"></i> 删除配方
        </el-button>

        <el-button class="alarm delete-drink-store float-right" type="text" size="mini" @click="handleDeleteDrinkSync">
          <i class="el-icon-collection"></i> 存储配方
        </el-button>
      </p>
      <div class="drink-store-edit-content">
        <div class="drink-store-edit-item">
          <p class="drink-store-edit-item-title">
            饮品信息
          </p>
          <div>
            <el-table :data="drinks" size="mini" height="240" maxHeight="240" border>
              <el-table-column label="饮品编号" align="center" min-width="120px">
                <template slot-scope="scope">
                  <span>{{scope.row.drinkUuid}}</span>
                </template>
              </el-table-column>
              <el-table-column label="饮品名称" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.name}}</span>
                </template>
              </el-table-column>
              <el-table-column label="饮品英文名称" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.engName}}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <span class="edit" @click="handleParamShow(scope.row.param, scope.$index)">
                    <i class="el-icon-edit"></i>编辑
                  </span>
                  <span class="delete" v-show="drinks.length >1" @click="handleRemoveDrink(scope.$index)">
                    <i class="el-icon-delete"></i>移除
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>

        </div>

        <div class="drink-store-edit-item">
          <p class="drink-store-edit-item-title">
            设备信息<span @click="handleDeviceShow"><i class="iconfont icontianjia"></i>新增同步设备</span>
          </p>
          <div class="drink-store-edit-item-content drink-height">
            <el-tag
              size="small"
              v-for="tag in devices"
              :key="tag.deviceUuid"
              @close="handleClose(tag)"
              closable>
              {{tag.deviceName}}
            </el-tag>
          </div>
        </div>

        <div>
          <el-button type="primary" size="small" @click="handlePushDevice">提交</el-button>
          <el-button @click="goBack" size="small">返回</el-button>
        </div>
      </div>

    </div>

    <c-dialog :visible.sync="bindVisible" :width="'42rem'" :height="'36rem'">
      <div slot="title" class="input-title">
        <p class="input-title-text">在线设备列表</p>
        <div class="input-title-container">
          <el-button type="primary" size="mini" plain @click="handleAddDevice">关联设备</el-button>
        </div>

        <div class="input-title-filter">
          <p>过滤条件:</p>
          <el-input v-model="searchDeviceName" placeholder="请输入设备名称" @change="inputChange" size="mini"/>
        </div>

      </div>

      <devices ref="devices"></devices>
    </c-dialog>

    <c-dialog :visible.sync="editDrinkVisible" :width="'42rem'" :height="'36rem'">
      <p class="config-drink-title">饮品参数<span class="remark">(向下滚动查看更多)</span>
        <el-button size="mini" type="primary" @click="handParamSave" plain>保存设置</el-button>
      </p>
      <recycle-drink-item :nodes="items" :depth="0" class="config-drink"></recycle-drink-item>

    </c-dialog>

    <div class="drink-store-edit-info">
      <p class="drink-store-edit-operate-title">同步操作说明</p>
      <ol class="drink-store-edit-info-content">
        <li class="error">若配方数据被修改以后，只有点击存储配方才会生效</li>
        <li class="alarm">若设备离线,则设备上线后会自动同步配方数据</li>
        <li class="alarm">修改饮品可通过批量定价处进行修改</li>
        <li class="alarm">设备列表中的饮品信息可进行单个饮品信息修改</li>
        <li>移除配方中饮品信息不会影响原始配方数据</li>
        <li>配方饮品不允许新增</li>
        <li>选取需要同步设备后，点击同步即可</li>
      </ol>
    </div>

    <loading :show="showLoading" :text="'设备饮品推送中，请耐心等待'"/>
  </div>
</template>

<script>

  import {drinkSyncDelete, drinkSyncProductUuid, drinkSyncPushDevice} from '@/api/equipment/drink'
  import CDialog from '@/components/Dialog'
  import Devices from './device_online'
  import Loading from '@/components/Loading'
  import RecycleDrinkItem from '@/components/RecycleDrinkItem'

  export default {
    name: '',
    components: {
      CDialog,
      Devices,
      Loading,
      RecycleDrinkItem
    },
    activated: function () {
      this.showLoading = false
      this.uuid = this.$route.params.uuid || ''
      if (!this.uuid) {
        this.$router.back()
        return;
      }
      this.devices = []

      this.productUuid = this.$route.params.productUuid || ''
      if (!this.productUuid) {
        drinkSyncProductUuid({uuid: this.uuid}).then(res => {
          this.productUuid = res.data
        })
      }

      this.drinks = this.$route.params.drink.params || []
      this.items = this.$route.params.drink.product || []
    },
    data: function () {
      return {
        uuid: '',
        productUuid: '',
        devices: [],
        drinks: [],
        searchDeviceName: '',
        bindVisible: false,
        showLoading: false,
        editDrinkVisible: false,
        paramIndex: 0,
        items: [
        ]
      }
    },
    methods: {
      goBack() {
        this.$router.back()
      },
      handleDeviceShow() {
        this.bindVisible = true
        let devices = []
        this.devices.forEach(item => {
          devices.push(item)
        })
        this.$nextTick(function () {
          this.$refs.devices.loadDevices(this.productUuid, this.searchDeviceName, devices)
        })
      },

      searchVariable(srcArray, dstObj) {
        srcArray = srcArray || []
        srcArray.forEach((item, index) => {
          if (item.type === 2) {
            let o = dstObj[item.variable]
            if (typeof (o) === "object") {
              this.searchVariable(item.nodes || [], o)
            }
          } else {
            let o = dstObj[item.variable]
            if (o !== undefined) {
              srcArray[index].default = o
            }
          }

        })
      },

      setVariable(srcObj, dstArr) {
        dstArr = dstArr || []
        dstArr.forEach(item => {
          if (item.type === 2) {
            let o = srcObj[item.variable]
            if (typeof (o) === "object") {
              this.setVariable(o, item.nodes || [])
            }
          } else {
            let o = srcObj[item.variable]
            if (o !== undefined) {
              srcObj[item.variable] = item.default
            }
          }

        })

      },

      handParamSave() {
        let drink = this.drinks[this.paramIndex]
        this.setVariable(drink.param, this.items)
        this.editDrinkVisible = false
      },

      handleParamShow(param, index) {
        if (!param) {
          return
        }
        this.paramIndex = index
        this.searchVariable(this.items, param)
        this.editDrinkVisible = true
      },

      inputChange(val) {
        this.$refs.devices.loadDevices(this.productUuid, val)
      },

      handlePushDevice() {
        if (this.devices.length === 0) {
          this.$message.warning("请选取需要推送的设备")
          return
        }
        let deviceUuid = []
        this.devices.forEach(item => {
          deviceUuid.push(item.deviceUuid)
        })
        let vue = this
        drinkSyncPushDevice({uuid: this.uuid, deviceUuid: deviceUuid, param: this.drinks}).then(res => {
          vue.showLoading = true
          setTimeout(function () {
            vue.showLoading = false
            vue.$message.success("饮品信息已下发")
          }, 2000 * deviceUuid.length)
        })
      },
      handleDeleteDrinkSync() {
        this.$confirm('此操作将删除饮品配方, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let vue = this
          drinkSyncDelete({uuid: vue.uuid}).then(res => {
            vue.$message.success('删除成功')
            vue.goBack()
          })


        }).catch(() => {

        })
      },
      handleRemoveDrink(index) {
        this.drinks.splice(index, 1)
      },
      handleClose(tag) {
        this.devices.splice(this.devices.indexOf(tag), 1);
      },
      handleAddDevice() {
        this.devices = this.$refs.devices.getDevices() || []
        this.bindVisible = false
      },
    },
  }
</script>

<style lang="scss">
  .drink-store-edit {
    display: flex;

    .drink-store-edit-operate-title {
      font-weight: 500;
      margin-bottom: $margin;
      height: $margin;
      line-height: $margin;
    }

    .drink-store-edit-operate {
      flex: 1;
      @include set_bg_color($background-color-light, $background-color-dark);
      padding: $padding;
      border-radius: $border-radius;
      display: flex;
      flex-direction: column;

      .drink-store-edit-operate-content {
        flex: 1;
        display: -webkit-box;
        -webkit-box-orient: horizontal;
        -webkit-box-pack: center;
        -webkit-box-align: start;

        display: -moz-box;
        -moz-box-orient: horizontal;
        -moz-box-pack: center;
        -moz-box-align: center;
      }
    }

    .drink-store-edit-info {
      width: 24%;
      @include set_bg_color($background-color-light, $background-color-dark);
      padding: $padding;
      margin-left: $margin;
      border-radius: $border-radius;
    }

    .drink-store-edit-info-content {
      font-size: 12px;
      @include set_bg_color(rgba(124, 198, 255, 0.56), $background-color-dark-1);
      padding: $padding-8;
      border-radius: $border-radius;
      list-style-type: none;
      counter-reset: sectioncounter;

      li:before {
        content: counter(sectioncounter) "、";
        counter-increment: sectioncounter;
      }
    }

    .drink-store-edit-content {
      padding: 0 $padding-8 $padding-8 $padding-8;

      .el-input-number__increase, .el-input-number__decrease {
        border-color: #D8DDE3;
      }
    }

    .config-drink-title {
      font-size: 16px;
      font-weight: bold;

      .remark {
        font-size: 13px;
        font-weight: normal;
      }

      .el-button {
        float: right;
      }
    }

    .config-drink-title:after {
      display: block;
      content: ' ';
      clear: both;
    }

    .config-drink {
      height: 31rem;
      overflow-y: scroll;

    }

    .alarm {
      color: $btn_color;
    }

    .error {
      color: $btn_sec_color !important;
    }

    .edit {
      cursor: pointer;
      color: $_theme_color;
      margin-right: $padding-4;
    }

    .delete {
      cursor: pointer;
      color: $btn_sec_color;
    }

    .el-tag + .el-tag {
      margin-left: 10px;
    }

    .drink-store-edit-item {
      margin-bottom: $padding;

      .drink-store-edit-item-title {
        margin-bottom: $padding-8;

        span {
          float: right;
          font-size: 12px;
          display: inline-block;
          padding-left: $padding-8;
          color: $_theme_color;
          cursor: pointer;

          i {
            font-size: 14px;
          }
        }
      }

      .drink-store-edit-item-content {
        padding: $padding-8;
        @include set_bg_color($background-color-light-2, $background-color-dark-1);
      }

      .drink-height {
        height: 180px;
        overflow-y: scroll;
      }
    }

    .delete-drink-store {

      i {
        font-size: 14px;
      }
    }

    .input-title {
      display: flex;
      padding: $padding 0;
      align-content: center;

      &-text {
        font-weight: 500;
        margin-right: $padding;
      }

      &-container {
        flex: 1;
        text-align: left;
      }

      &-filter {
        //flex: 1;
        display: flex;
        text-align: right;
        align-content: center;
        margin-right: $padding;

        p {
          width: 120px;
          font-size: 12px;
          margin-right: $padding;
          text-align: right;
        }
      }
    }
  }


  body.dark {
    .drink-store-edit-content {
      .el-input-number__increase, .el-input-number__decrease {
        border-color: #889DB8;
      }
    }

    .delete-drink-store {
    }
  }
</style>
